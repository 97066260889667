import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "新卒配属2ヶ月目でChatGPTを活用した新規機能開発プロジェクトにアサインされた話",
  "date": "2024-03-29T08:00:00.000Z",
  "slug": "entry/2024/03/29/191108",
  "tags": [],
  "hero": "./2024-03-29.png",
  "heroAlt": "新卒配属2ヶ月目でChatGPTを活用した新規機能開発プロジェクトにアサインされた話"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <h2>{`自己紹介`}</h2>
    <p>{`はじめまして。人材プラットフォーム本部プロダクト開発室第一開発グループの田中です。`}</p>
    <p>{`私は、2023 年の 4 月に新卒エンジニアとして入社し、日本最大級の医療介護求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」のフロントエンドとバックエンドの開発を担当しています。`}</p>
    <p>{`学生時代は情報系ではなく、経済学を専攻していました。プログラミングには大学の授業で出会い、主にデータ分析に用いる `}<a parentName="p" {...{
        "href": "https://www.r-project.org/"
      }}>{`R`}</a>{` や `}<a parentName="p" {...{
        "href": "https://www.python.org/"
      }}>{`Python`}</a>{` を触っていました。そのため Web アプリケーションの開発は行っていませんでした。`}</p>
    <p>{`エンジニアという職業に興味を持ったきっかけは、大学院時代に参加したインターンでした。インターン先の会社ではエンジニアが誰もいなかったのですが、開発未経験の私に機械学習を使ったアプリの PoC を作れという、かなり破天荒な経験をしたためです。今ではとてもレアな経験だったと思いますが、試行錯誤しながら何かを作ることに興奮を覚えたのを今でも鮮明に覚えています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/"
      }}>{`メドレー`}</a>{`に入社した理由は、大きな社会課題に取り組んでいる、触れられる技術がフロントエンド・バックエンドのように領域に限られていないなどありましたが、一番大きな理由は「`}<strong parentName="p">{`技術はあくまで手段であり、顧客への価値提供を目的にしている`}</strong>{`」という考えに共感したことです。入社してまだ日は浅いですが、この考えは随所で感じられます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b77433ed47973e9768545abededfb3f3/d2602/dev_202403_00.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCA//EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGbTjaSGf/EABsQAAMAAgMAAAAAAAAAAAAAAAABAgMEERIU/9oACAEBAAEFAkqY8dHU9bK2HQ8lc//EABgRAAIDAAAAAAAAAAAAAAAAAAABAgMh/9oACAEDAQE/AVGsw//EABYRAQEBAAAAAAAAAAAAAAAAAAASAv/aAAgBAgEBPwG9Kf/EABUQAQEAAAAAAAAAAAAAAAAAABBB/9oACAEBAAY/Amv/xAAbEAEBAAEFAAAAAAAAAAAAAAABAEEQITFRgf/aAAgBAQABPyFTh30ChgjLwu+shf/aAAwDAQACAAMAAAAQqD//xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhQf/aAAgBAwEBPxBdWiMH/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/EL6l/8QAHRABAAIABwAAAAAAAAAAAAAAAQARIUFxgZGh0f/aAAgBAQABPxBIi5iCLR4jQWt5V+gg2FdU8l5H/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "大学近くのビーチの夕焼け",
            "title": "大学近くのビーチの夕焼け",
            "src": "/static/b77433ed47973e9768545abededfb3f3/e5166/dev_202403_00.jpg",
            "srcSet": ["/static/b77433ed47973e9768545abededfb3f3/f93b5/dev_202403_00.jpg 300w", "/static/b77433ed47973e9768545abededfb3f3/b4294/dev_202403_00.jpg 600w", "/static/b77433ed47973e9768545abededfb3f3/e5166/dev_202403_00.jpg 1200w", "/static/b77433ed47973e9768545abededfb3f3/d9c39/dev_202403_00.jpg 1800w", "/static/b77433ed47973e9768545abededfb3f3/df51d/dev_202403_00.jpg 2400w", "/static/b77433ed47973e9768545abededfb3f3/d2602/dev_202403_00.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`大学近くのビーチの夕焼け`}</em></p>
    <h2>{`入社から初プロジェクトまで`}</h2>
    <p>{`さて、今回の記事では新卒エンジニアの私が初めて携わったプロジェクトの紹介を通して、メドレーでは新卒エンジニアが入社後にどのような働き方をしてるか伝えることができればと思います。`}</p>
    <p>{`入社してから研修を終えて、配属後 1 ヶ月半くらいは主にプロダクトの定常改善系のタスクに取り組みながら、仕事のリズムを覚えていきました。`}</p>
    <p>{`その後、今回紹介するプロジェクトに初めてアサインされました。この時点でプロジェクトで主に使用するスキルセットのフロントエンドは、新卒研修で学んだ程度でした。そのような状態の新卒でも、経験をたくさん積ませてもらえる良い環境だということもお伝えできればと思っています。`}</p>
    <h1>{`プロジェクトで解決する課題と解決策`}</h1>
    <p>{`まず、今回担当したプロジェクトが発足した背景となる課題と、その課題を解決するために提供した新機能について紹介します。このプロジェクトについては、`}<a parentName="p" {...{
        "href": "https://www.medley.jp/release/20240313.html"
      }}>{`ニュースリリース`}</a>{`も公開していますので、合わせてご覧ください。`}</p>
    <h2>{`ユーザーの抱える課題`}</h2>
    <p>{`これまで「ジョブメドレー」では顧客が求人を作成する際に、フォーム形式で募集要項を記入する形式となっていました。記入の自由度が高いことから、法令やガイドライン等で定められた項目を漏れなく記載することや、事業所の魅力を効果的に伝える工夫が必要であり、顧客にとっては大きな負担になっていました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/15bba8d14902f8487cbae3ddc7cf8420/bb3b7/dev_202403_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABGUlEQVQoz4VTi46EIAz0/7/0LusCJyiKj7lMwxjc01xNRQodpkztsvOIzmEpBaUUTNOElBKO4wBtnmeM4whZzhkxxnOda845eO8tv+NrWRbzdV0NMIRgi/u+W5xJ27YZiAAZ55w5pZLh/o6BNsjE1pn05AQQU1nHklgiT74D/M//MGSJvAOCapGHsCRt1OZPJzuWf7nDtUl6YqjyBNLOLWYPzLvZB7y/vjHlfIpCthKL3xKBJgFRAbY0orwd1vCD3RjWMqWyAGRqCzLR/NJGMWJ49TYeUrltG24ehuFMIGO6jPfLA3XAECNefY9UW+sCSKaac2xV5PeTMJe2+WSoxuVINlJYAt0JY/dZY5c/RQzpLUjrd4Bts/8Cxy6un2/dJ4QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "フォーム形式の求人作成機能のイメージ",
            "title": "フォーム形式の求人作成機能のイメージ",
            "src": "/static/15bba8d14902f8487cbae3ddc7cf8420/c1b63/dev_202403_01.png",
            "srcSet": ["/static/15bba8d14902f8487cbae3ddc7cf8420/5a46d/dev_202403_01.png 300w", "/static/15bba8d14902f8487cbae3ddc7cf8420/0a47e/dev_202403_01.png 600w", "/static/15bba8d14902f8487cbae3ddc7cf8420/c1b63/dev_202403_01.png 1200w", "/static/15bba8d14902f8487cbae3ddc7cf8420/bb3b7/dev_202403_01.png 1211w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`フォーム形式の求人作成機能のイメージ`}</em></p>
    <p>{`社内オペレーションの観点で見ると、求人が掲載基準を満たしているのか、入力された文章から読み取り確認する業務に時間がかかっていました。`}</p>
    <p>{`そこで、顧客と社内オペレーションの双方が感じている課題を解決するプロジェクトが発足し、私も参画しました。`}</p>
    <h2>{`ユーザー目線の解決策`}</h2>
    <p>{`顧客と社内オペレーションの双方の課題を解決するため、求人作成プロセスをより直感的かつ効率的に改善しました。具体的には、従来のフォーム入力方式から、対話式の質問と回答を通じて求人を作成できる機能を導入しました。この改善により、顧客は簡単に、迷うことなく掲載基準に沿った求人を作成しやすくなりました。`}</p>
    <p>{`さらに、文章作成の労力を軽減するために、 `}<a parentName="p" {...{
        "href": "https://chat.openai.com/"
      }}>{`ChatGPT`}</a>{` を活用した提案機能も導入しました。この機能を通じて、顧客は入力した内容に基づき、求職者の目に止まりやすい求人タイトルや訴求文を ChatGPT から提案してもらえます。これにより、求人原稿作成にかかる手間や煩雑さが軽減され、専任の採用担当がいない事業所でも採用活動を効率的に進めることができ、迅速な人材の確保へとつながります。社内オペレーションの観点でも、掲載基準を満たしているかを確認する業務の時間短縮が期待できます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1059px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/67529b71def350c7d2fda231de59be7c/1be7e/dev_202403_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB+0lEQVQ4y51U22rcMBT0//9NvyGFUvpQ2kIgpRdKdlNbK1sXW3fJU47W2nVDQ9IIBslYGs05M3b36TvDlx8MyzJjnhfM84ycM1JKL0IpBakAMQMpZXQPTKDnEtYsWJYzIW36n/Hxp8LbOwUbMrrPm0IiHoWuSklhG+u6PolS1rpnUBGHKcHHjG4UCnyUkFJB67kSUimMMQzDACkltNYwxvwFaw2cNXVvGySkE2LCMPRg22EqO8UEKcSFbJqm2gpaK6XqWkiNY8/hfcBaK9kIpVKXA62HKUYsxmJezGbWFVSFMwu+HUa8ef8L2rjHCgU455WsEb7E5RAjrA/IuSBkVFSXqaxxHEHEr3GZ4nJzy/Hu6+ZyI6TZWntR+JzDZxSkvOKeOxxFgg+7komU5trDlMD5iIGdIDb3jbUwxtbemh1IhHcOKBkl7xQSmjlE+Jtr3DONfjQYJgOuHE7S4WE0YMLiJC2UCTA+YjYeMaarKUTU8tUIz/HQl77SM8WmrffOtzOVcJ+t/cuWOyqXLiTQ5bSXILacqm1PjPFM2OLSlDRCKcWlp0TQQt4uarn1ziJ5e1X4WDr9cWINNoXa1LJJTVO0Xy+zxpEJfLjrayZLeUJhzunypTh7dvJfCN7hwBRubnu4kGqMuj1Zm70n1wJiCAjPIKcIrKl+rnTuD50lIumD/55AAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "質問形式の求人作成補助機能のイメージ",
            "title": "質問形式の求人作成補助機能のイメージ",
            "src": "/static/67529b71def350c7d2fda231de59be7c/1be7e/dev_202403_02.png",
            "srcSet": ["/static/67529b71def350c7d2fda231de59be7c/5a46d/dev_202403_02.png 300w", "/static/67529b71def350c7d2fda231de59be7c/0a47e/dev_202403_02.png 600w", "/static/67529b71def350c7d2fda231de59be7c/1be7e/dev_202403_02.png 1059w"],
            "sizes": "(max-width: 1059px) 100vw, 1059px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`質問形式の求人作成補助機能のイメージ`}</em></p>
    <p>{`ChatGPT の導入有無に関しては社内でも協議され、文章生成に ChatGPT を用いることで顧客の作業コストを下げられるのであれば導入しようという意思決定がありました。流行っている技術だから導入するのではなく、`}<strong parentName="p">{`顧客への価値提供のために新しい技術を導入する`}</strong>{`考えが現れた意思決定だと思います。`}</p>
    <h2>{`技術スタック`}</h2>
    <p>{`今回のプロジェクトでは次のような技術スタックで開発を行いました。フロントエンドは `}<a parentName="p" {...{
        "href": "https://ja.reactjs.org/"
      }}>{`React`}</a>{` を使用し状態管理ライブラリとして `}<a parentName="p" {...{
        "href": "https://ja.react.dev/reference/react/hooks"
      }}>{`React Hooks`}</a>{` を選択しました。従来の求人作成機能では `}<a parentName="p" {...{
        "href": "https://redux.js.org/"
      }}>{`Redux`}</a>{` で状態管理を行っていましたが、プロダクト全体の方針として今後 React Hooks を使用していく方針であったことと、実装のスピードを早めるために React Hooks を採用しました。`}</p>
    <p>{`ChatGPT を用いた部分に関しては、顧客からのリクエストを受けて発行したトークンを保存するために `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/dynamodb/"
      }}>{`Amazon DynamoDB`}</a>{` を使用しました。DynamoDB に保存したトークンのバリデーションと GPT へプロンプトを渡し、提案の受け取り・返却する部分は `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/lambda/"
      }}>{`AWS Lambda`}</a>{` function に任せていました。GPT 本体は `}<a parentName="p" {...{
        "href": "https://azure.microsoft.com/ja-jp/products/ai-services/openai-service"
      }}>{`Azure OpenAI Service`}</a>{` を利用する構成でした。Azure OpenAI Service を採用した理由は、顧客情報や入力内容が学習に使用されることがなくプライバシー面でのリスクを回避できるためでした。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "992px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/485102689581d39e9f4b4070b5930b02/91f10/dev_202403_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAACFUlEQVQoz3WSS2tTURSFM/YvOHHgLxD/iVNHztQillIQJyIIQTBChY46KU4lokIpVqVSKRjE2lfStInpTWqb1PTe3Ne5z3PvJ+ekCSnqgc0+r732WuucQhTFLK+ssrG1y/KHVQ7bR0wOmWc6v2y3sOJIz/Pzs1rdY/rBHvdma8w8rBMnGQXP81l6/wlrYPP12wb7jZ8EQuC4Ls5gQCglS91fvGjUCaTEdRxsxyUQNpvbDvfPAW/d2SGMJIU0TXm7tMLrd8sUn8/TMjr0+30OGg163S4pOU3PpS18zap3espBo4lpdtncdpmaqWmWN27+IFEM8zynf2bqOGgeIkQwlJUPhYVpgkhiHWkm9V6WZQjhY9shO1WXrV2HvX1P1xRGha7rjn0b7Y3y5IiiSN/tdnvU63tIGV6ouwCYJMlfANJqkx59JzEqSNPA8XyUTXEc6/Nqtcba2heCYAg8BgyCACml7qIkJVGIEijeTDN4chVz9hL+q9uoN8/SYeNms8nc3JyOhYUFXT8G9DyP4+NjTNPEtm3OfvdQPYPyXazHV7AeXUaUpxiEOR2jpRVVKhVKpRKLi4sUi0VtxxjQ9306nQ6WZenLwveGzD8+xX52Dbt0nehzCcWtbbQ0ASW9XC5r0PX19aHk/5k/+sB5LMiESeaf6fnkUKAnJycYhqG/mrKtMPmiyjuVR8E/Gk3eU3nku2Kr1n8ACtgsqjv2fzMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "文章生成機能の構成イメージ",
            "title": "文章生成機能の構成イメージ",
            "src": "/static/485102689581d39e9f4b4070b5930b02/91f10/dev_202403_04.png",
            "srcSet": ["/static/485102689581d39e9f4b4070b5930b02/5a46d/dev_202403_04.png 300w", "/static/485102689581d39e9f4b4070b5930b02/0a47e/dev_202403_04.png 600w", "/static/485102689581d39e9f4b4070b5930b02/91f10/dev_202403_04.png 992w"],
            "sizes": "(max-width: 992px) 100vw, 992px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`文章生成機能の構成イメージ`}</em></p>
    <h1>{`プロジェクトへの関わり`}</h1>
    <p>{`今回のプロジェクトのメンバー構成はエンジニア 3 人、デザイナー 1 人、PM 1 人という構成でした。開発には約 3 ヶ月の期間を要しました。`}</p>
    <h3>{`プロジェクト初期`}</h3>
    <p>{`私自身のプロジェクトへの関わり方としては、プロジェクト初期段階からフロントエンドの実装タスクが中心でした。その中でスタイルの実装に関してはオーナーシップを持たせていただきました。今回のプロジェクトで作成する UI コンポーネントでは、似たようなものが複数あったため、できるだけ拡張性を持たせ使い回せるように意識して設計していました。仕事のサイクルとしては、デザイナーが作ったデザインを元に、細かい仕様を調整して実装し、デザインレビューをしてもらうサイクルを回していました。`}</p>
    <p>{`ただ、React などをキャッチアップしながらの実装だったため、他のエンジニアより実装に時間がかかってしまっていました。適宜先輩エンジニアに質問しながら進めていましたが、いくつかのタスクは巻き取ってもらうことになるなど、悔しい気持ちを覚えたプロジェクト初期でした。`}</p>
    <h3>{`プロジェクト中盤以降`}</h3>
    <p>{`プロジェクト中盤あたりで一つエンジニアとしての仕事の向き合い方に変化がありました。プロジェクトのスケジュールがタイトだったのも相まって、プロジェクト初期ではとりあえず実装して早くタスクを終わらせることに注力していました。しかし、時間がかかっても良いから一つ一つ丁寧にコードを追って理解することに努めようと先輩エンジニアにアドバイスをいただきました。`}</p>
    <p>{`そこから、理解に注力することで思考がクリアになり、結果的に実装スピードが上がっていきました。また、実装以外にも目を向ける余裕が生まれ、仕様について PM やデザイナーに提案することもできました。プロジェクト中盤以降は自信を持ってタスクに取り組むことができ、終盤には他のエンジニアのタスクを巻き取ることができるまでになりました。`}</p>
    <p>{`プロジェクトの中では、プロンプトの改善タスクも任せてもらえました。内容としては意図しない回答を弾く改善でしたが、AWS Lambda を触るなど、必要があればクラウドサービスを触る機会もあると感じました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "717px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f67c2838c4113f103df96ef7a3267009/0ad97/dev_202403_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "101.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC1UlEQVQ4y4VUy47TQBDM5/EBfANXfoA7B86cuHHlyAkhcUMCpEUrQNrlmTgv2/EjsePxvDwuVJ3MxgIkLJXa6emu6a5uZxbCiBAgGEZgxAUBJ5/YAPhhhA/jyU5x9g1hxIyZadfj277Grmrxfb7Gl9sfuPk+x3yZ4tcyxe2PBapGo/eAshO4CSxgPE6EldLYNC0OrUJe7gVZUaOsW9RNh6Ju0CoDZTw67cUK9Blnv3EBM5apjYO3FmVZYr/fwxgjtmkaHA4HVFWFrutQ17X4aIuikJjdbidWqR7a+jOhdXDOySGTSdj3vdj4znOttbzT8rf3fmI9tB1IGKTCEAI2mw22260Q53kuNxOxsuVyiSRJ5J3POI6IzzAMscKA/kxIsvl8LolZlklbrJiEtLF1PoyPILEnoXEXQjqVUpJMsLK2bcXHio7H410108ricyEcToTWWkmiPiQkmTZaCPlOLeOQGBsvZY41BuS5EGorrbDF1WolSJYJ0jQVH0EJqC015G/qzTjKwItOQ/EnQlkb7+TGxWIhySSLlbKqaKkzz6O2zOEFx66DccOFkOPnSlAfTiyKHRG1mw4jxtA67/+c8iDakJQ27toU3Dee8cKpn4SnoZwJudgMTNNMdFmv17I63EW2FjVku4skkdb5VTGOPp5zBsbGlq3D4P3dEjOA09XytWipdro+rJRTp66yWl0nMXctd71Brw061eN47ATaGFjnYFi9IzycH8RqYwU8i1DkYMuUe5tX+PozwWKVYrUtUOyPyIoD1lmJrGyQlQekxR551WCTVVhucqzTXP6ViuogSPMKdgBmHGDdKKzTUpJI1PYeyo7oTPgL9B86j2SnsMgVkjNWO4XeBMzCOEJ7oHcXMOlfOJpR/snf3lS4/+Q9Hj3/jIfPrvHg6RXuPX6HT0lzHoob0dvwXygbYP2IVdHj5Yct3lxneP0xxaurFC/ebbDdG/wGecb/RfSlRnEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ChatGPT による提案機能のイメージ",
            "title": "ChatGPT による提案機能のイメージ",
            "src": "/static/f67c2838c4113f103df96ef7a3267009/0ad97/dev_202403_03.png",
            "srcSet": ["/static/f67c2838c4113f103df96ef7a3267009/5a46d/dev_202403_03.png 300w", "/static/f67c2838c4113f103df96ef7a3267009/0a47e/dev_202403_03.png 600w", "/static/f67c2838c4113f103df96ef7a3267009/0ad97/dev_202403_03.png 717w"],
            "sizes": "(max-width: 717px) 100vw, 717px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`ChatGPT による提案機能のイメージ`}</em></p>
    <h1>{`さいごに`}</h1>
    <h2>{`プロジェクトを振り返って`}</h2>
    <p>{`新機能がリリースされて数ヶ月経ちましたが、定めている KPI を達成し、結果が現れていることがとても嬉しいです。一例として、顧客が新機能で求人を作成してから社内での審査を経て掲載に至るまでの日数が、従来の作成方法と比べて半分以下と大幅に削減されています。`}</p>
    <p>{`チームとしては今回のプロジェクトの進め方について振り返りを行いました。PM 、デザイナー、エンジニアそれぞれが次のプロジェクトをより良く遂行する提案を出し合い、今回のプロジェクトの経験が無駄にならないようにしています。現在、私自身はエンジニア一人の新しいプロジェクトを任されていますが、この時の振り返りでの反省をタスク分解やスケジュール策定に役立てられています。`}</p>
    <p>{`個人的には今回のプロジェクトを通して、フロントエンドの技術習得はもちろんのこと、プロジェクトメンバーとのコミュニケーションで文章の構造化や画像に編集を加えるなどの工夫を加えることでコミュニケーションコストを下げることができるといった技術以外のことも学ぶことができました。`}</p>
    <p>{`またプロジェクトを通して、若手のエンジニアが成長できる環境だと肌で感じました。配属後まもなくプロジェクトに参画させてもらえることや必要に応じて様々な技術を触らせてもらえることはもちろんのことですが、分からないことを聞きやすくしてくれる雰囲気や手を上げたら挑戦させてもらえる環境は贅沢に感じるほどです。`}</p>
    <p>{`このような成長できる環境で若手時代を過ごしたい新卒エンジニアの方や、技術はあくまで手段であり、顧客への価値提供を目的にしているチームで働きたいエンジニアの方を絶賛募集していますので、ご興味がある方はぜひカジュアルにお話から始めてみませんか。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      